import React, { useEffect, useRef, useState } from 'react';
import { graphql } from "gatsby"
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

import parse from 'html-react-parser';

import Layout from "../../components/layout"
import Seo from "../../components/seo"

//sections
import InnagoHeroSection from '../../pages/sections/caseStudies/Innago/InnagoHeroSection';
import ChallengesSection from '../sections/caseStudies/ChallengesSection';
import InnagoSection1 from '../sections/caseStudies/Innago/InnagoSection1';
import InnagoSection2 from '../sections/caseStudies/Innago/InnagoSection2';
import InnagoSection3 from '../sections/caseStudies/Innago/InnagoSection3';
import InnagoSection4 from '../sections/caseStudies/Innago/InnagoSection4';
import TestimonialSection from '../sections/TestimonialSection';


const Innago = ({data}) => {
  const mainData = data.allWpPage.nodes[0].caseStudyInnagoPageACF;
  const sectionRef = useRef();
  const [bgTransition, setBgTransition] = useState('');
  return (
    <Layout headerClass="white-header">
      <Seo title="Innago" />
      <div className={"innago-case-study page-wrapper " + bgTransition} id="cookingenie-casestudy-page-wrapper" ref={sectionRef}>
          <InnagoHeroSection 
            preTitle={parse(mainData.innagoHeroSectionPretitle)}
            mainTitle={parse(mainData.innagoHeroSectionTitle)}
            image1x={
              {
                sourceUrl: mainData.innagoHeroSectionImage1x.sourceUrl,
                altText: mainData.innagoHeroSectionImage1x.altText,
                width: mainData.innagoHeroSectionImage1x.width,
              }
            }
            image2x={
              {
                sourceUrl: mainData.innagoHeroSectionImage2x.sourceUrl,
                altText: mainData.innagoHeroSectionImage2x.altText,
                width: mainData.innagoHeroSectionImage2x.width,
              }
            }
          />
          <ChallengesSection
            companyInfoList={mainData.innagoChallengeSectionCompanyInfoList}
            challengesList={mainData.innagoChallengeSectionChallangesList}
          />
          <InnagoSection1 
            mainTitle={parse(mainData.innagoSection1Title)}
            mainDescription={parse(mainData.innagoSection1Description)}
            imageslist={mainData.innagoSection1ImageList}
          />
          <InnagoSection2 
            mainTitle={parse(mainData.innagoSection2Title)}
            mainDescription={parse(mainData.innagoSection2Description)}
            imageslist={mainData.innagoSection2ImageList}
            mainTitle2={parse(mainData.innagoSection2Title2)}
            imageslist2={mainData.innagoSection2ImageList2}
            imageslist3={mainData.innagoSection2ImageList3}
          />
          <InnagoSection3 
            mainTitle={parse(mainData.innagoSection3Title)}
            imageslist={mainData.innagoSection3ImageList}
          />
          <InnagoSection4 
            mainTitle={parse(mainData.innagoSection4Title)}
            imageslist={mainData.innagoSection4ImageList}
          />
          <TestimonialSection
            mainTitle={parse(mainData.innagoTestimonialSectionTitle)}
            message={parse(mainData.innagoTestimonialSectionDescription)}
            name={parse(mainData.innagoTestimonialSectionName)}
            designation={parse(mainData.innagoTestimonialSectionDesignation)}
            headshot1x={
              {
                sourceUrl: mainData.innagoTestimonialSectionHeadshot1x.sourceUrl,
                altText: mainData.innagoTestimonialSectionHeadshot1x.altText,
                width: mainData.innagoTestimonialSectionHeadshot1x.width,
              }
            }
            headshot2x={
              {
                sourceUrl: mainData.innagoTestimonialSectionHeadshot2x.sourceUrl,
                altText: mainData.innagoTestimonialSectionHeadshot2x.altText,
                width: mainData.innagoTestimonialSectionHeadshot2x.width,
              }
            }
            logo={
              {
                sourceUrl: mainData.innagoTestimonialSectionLogo.sourceUrl,
                altText: mainData.innagoTestimonialSectionLogo.altText,
                width: mainData.innagoTestimonialSectionLogo.width,
              }
            }
          />
      </div>
    </Layout>
  )
}

export const InnagoQuery = graphql`
  {
    allWpPage(filter: {slug: {eq: "innago"}}) {
      nodes {
        caseStudyInnagoPageACF {
          innagoHeroSectionPretitle
          innagoHeroSectionTitle
          innagoHeroSectionImage1x {
            altText
            sourceUrl
            width
          }
          innagoHeroSectionImage2x {
            altText
            sourceUrl
            width
          }
          innagoChallengeSectionCompanyInfoList {
            title
            description
          }
          innagoChallengeSectionChallangesList {
            title
            description
          }
          innagoSection1Title
          innagoSection1Description
          innagoSection1ImageList {
            image1x {
              altText
              sourceUrl
              width
            }
            image2x {
              altText
              sourceUrl
              width
            }
          }

          innagoSection2Title
          innagoSection2Description
          innagoSection2ImageList {
            image1x {
              altText
              sourceUrl
              width
            }
            image2x {
              altText
              sourceUrl
              width
            }
          }
          innagoSection2Title2
          innagoSection2ImageList2 {
            image1x {
              altText
              sourceUrl
              width
            }
            image2x {
              altText
              sourceUrl
              width
            }
          }
          innagoSection2ImageList3 {
            image1x {
              altText
              sourceUrl
              width
            }
            image2x {
              altText
              sourceUrl
              width
            }
          }
          innagoSection3Title
          innagoSection3ImageList {
            image1x {
              altText
              sourceUrl
              width
            }
            image2x {
              altText
              sourceUrl
              width
            }
          }
          innagoSection4Title
          innagoSection4ImageList {
            image1x {
              altText
              sourceUrl
              width
            }
            image2x {
              altText
              sourceUrl
              width
            }
          }
          innagoTestimonialSectionTitle
          innagoTestimonialSectionDescription
          innagoTestimonialSectionDesignation
          innagoTestimonialSectionName
          innagoTestimonialSectionHeadshot1x {
            altText
            sourceUrl
            width
          }
          innagoTestimonialSectionHeadshot2x {
            altText
            sourceUrl
            width
          }
          innagoTestimonialSectionLogo {
            altText
            sourceUrl
            width
          }
        }
      }
    }
  }
`

export default Innago
