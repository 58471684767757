import * as React from "react"
import shortid from  "shortid";

const InnagoSection3 = (props) => {
    return (
        <section className="section-3">
            <div className="container">
                <div className="title-block">
                    <div className="row">
                        <div className="col-lg-2"></div>
                        <div className="col-lg-17">
                            <h2 className="h1 section-title extra-spacing">{props.mainTitle}</h2>
                        </div>
                        <div className="col-lg-5"></div>
                    </div>
                </div>
                <div className="image-block">
                    <div className="row">
                        {props.imageslist?.map((object, i) => {
                            return (
                                <div className={((i+1)%3 === 1) ? 'col-lg-24' : 'col-lg-12'} key={shortid.generate()}>
                                    <div className="item">
                                        <img
                                            src={object.image1x?.sourceUrl}
                                            srcSet={object.image2x?.sourceUrl + " 2x, " + object.image1x?.sourceUrl + " 1x"}
                                            width={object.image1x?.width}
                                            alt={object.image1x?.altText}
                                        />
                                    </div>
                                </div>     
                            )
                        })}
                    </div>
                </div>
            </div>
        </section> 
    )
}

export default InnagoSection3;